<template>
  <div>
    <a :href="link" class="external-link" :class="{centerAlign: iconImage == null}" target="_blank">
      <img :src="iconImage" alt="icon" v-if="!iconImage">
      <slot></slot>
    </a>
    <img :src="img" alt="image" v-if="img">
  </div>
</template>

<script>

export default {
  props:{
    link:{
      required: true,
      default: "#",
    },
    iconImage:{
      required: false,
      default: null,
      type: String
    },
    img:{
      required: false,
      default: null,
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>
  .external-link{
    padding: 10px;
    display: block;
    width: 180px;
    font-size: 12px;
    color: white;
    text-decoration: none;
    background: var(--redC41230);
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    margin-top: 15px;


    &.centerAlign{
      justify-content: center;
    }
    
    img{
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }






  }
</style>