<template>  
  <li>
    <router-link tag="a" :to="to" class="nav-item"> 
      <img :src="iconImage" alt="icon">
      <p>{{name}}</p>
    </router-link>
  </li>
</template>

<script>
import DashboardIcon from '../assets/icons/dashboard.svg';
import LeaderboardIcon from '../assets/icons/leaderboard.svg';
import ContractIcon from '../assets/icons/contract.svg';
import InviteIcon from '../assets/icons/friends.svg';
import WalkthroughIcon from '../assets/icons/walkthrough.svg';
import RulesIcon from '../assets/icons/rules.svg';
import ContactIcon from '../assets/icons/contact.svg';
import SignoutIcon from '../assets/icons/signout.svg';
import UsersIcon from '../assets/icons/users.svg';
import MarketDataIcon from '../assets/icons/market_data.svg';
import FeedbackIcon from '../assets/icons/feedback.png';
import UserWhite from '../assets/icons/user_white.png';


export default {
  props:["icon","name","to"],
  data(){
    return{
      dashboard: DashboardIcon,
      leaderboard: LeaderboardIcon,
      contract: ContractIcon,
      invite: InviteIcon,
      walkthrough: WalkthroughIcon,
      rules: RulesIcon,
      contact: ContactIcon,
      signout: SignoutIcon,
      users: UsersIcon,
      marketData: MarketDataIcon,
      feedback: FeedbackIcon,
      userWhite: UserWhite
    }
  },
  computed:{
    iconImage() {
      switch (this.icon) {
        case "dashboard":
          return this.dashboard;
        case "leaderboard":
          return this.leaderboard;
        case "contract":
          return this.contract;
        case "invite":
          return this.invite;
        case "walkthrough":
          return this.walkthrough;
        case "rules":
          return this.rules;
        case "contact":
          return this.contact;
        case "signout":
          return this.signout;
        case "users":
          return this.users;
        case "marketdata":
          return this.marketData
        case "feedback":
          return this.feedback
        case "user":
          return this.userWhite
        default:
          return "";
      }
    },
  }
}
</script>

<style lang="scss" scoped>

li{
  list-style-type: none;
}

.nav-item{
  display: flex;
  align-items: center;
  padding: 10px;


  img{
    margin-right: 15px;
    width: 15px;
    height: 15px;
  }

  p{
    color: white;
    font-size: 12px;
    letter-spacing: 1.2px;
  }

  &.router-link-active{
    background: var(--redC41230);

    img{
      color: white;
    }
  } 
}
</style>