<template>
  <header>
    <router-link tag="a" to="/">
      <img src="../assets/images/kenanga_logo.png" alt="logo">
    </router-link>
    <div class="user-section" @click="openProfile">
      <template v-if="userProfile.avatar_arr[0]">
        <img class="avatar" :src="userProfile.avatar_arr[0]"/>
      </template>

      <template v-else>
        <img class="avatar" src="../assets/images/user_default.png"/>
      </template>
      <p class="user-name">{{userProfile.display_name || 'Player 1'}}</p>
      <p class="user-role">{{userProfile.role.name === 'user' ? 'Player' : userProfile.role.name}}</p>
    </div>
  </header>
</template>

<script>
export default {
  methods:{
    openProfile(){
      const path = '/profile'
      if(this.$route.path !== path){
        if(this.userRole == 'admin'){
          this.$router.push({name:'AdminViewUserProfile'})
        }else{
          this.$router.push({name:'Profile'})
        }
      }
    }
  },
  computed:{
    userProfile(){
      return this.$store.getters.getUserProfile;
    }
  }
}
</script>

<style scoped lang="scss">
header{
  background: white;
  padding: 0px 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px){
    padding: .5rem 1rem;
  }

  img{
    width: 150px;
    height: auto;

    @media screen and (max-width: 768px){
      width: 100px;
    }
  }

  .user-section{
    background: var(--whiteFFFFFF);
    // padding: 1.5rem 3rem;
    padding-right: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    cursor: pointer;
    .avatar{
      display: block;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      position: relative;
      margin-right: 1rem;
      grid-column: 1;
      grid-row: 1/3;
      align-self: center;
      object-fit: contain;

      &::before{
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        display: block;
        background: var(--green009F00);
        width: 13px;
        height: 13px;
        border-radius: 11.5px;
      }
    }

    .user-name{
      grid-column: 2;
    }

    .user-role{
      grid-column: 2;
      color: #8A8B8A;
      font-size: 13px;
      text-transform: capitalize;
    }
  }
}
</style>